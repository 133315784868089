/* global ym */
import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function Footer() {
  const { t } = useTranslation();
  return (
    <footer className="footer"> 
      <div className="footer__container">
        <p className="footer__container-text">
          &copy; {t('footer-copyright-text')}
        </p>
      </div>
    </footer>
  );
}

export default Footer;
