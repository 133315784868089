/* global ym */
import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function NavBar() {
  const { t } = useTranslation();

  return (
    <nav className="navBar">
      <ul className="navBar__list">
        <li>
          <NavLink
            to="/"
            className={({ isActive }) =>
              `navBar__list-item-calculator ${isActive ? 'active' : ''}`
            }
            onClick={() => ym(98258836, 'hit', {})}
          >
            {t('first-nav-link-text')}
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/how-it-works"
            className={({ isActive }) =>
              `navBar__list-item ${isActive ? 'active' : ''}`
            }
            onClick={() => ym(98258836, 'hit', {})}
          >
            {t('second-nav-link-text')}
          </NavLink>
        </li>
        <li>
          <a
            href="https://t.me/+BePAPTdcbEc5NjJi"
            className="navBar__list-item-tg"
            target="_blank"
            rel="noreferrer"
          >
            {t('third-nav-link-text')}
          </a>
        </li>
        <li>
          <NavLink
            to="/updates"
            className={({ isActive }) =>
              `navBar__list-item ${isActive ? 'active' : ''}`
            }
            onClick={() => ym(98258836, 'hit', {})}
          >
            {t('five-nav-link-text')}
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/help-the-project"
            className={({ isActive }) =>
              `navBar__list-item-help ${isActive ? 'active' : ''}`
            }
            onClick={() => ym(98258836, 'hit', {})}
          >
            {t('fourth-nav-link-text')}
          </NavLink>
        </li>
      </ul>
    </nav>
  );
}

export default NavBar;
