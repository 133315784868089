import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

function HelpTheProject() {
  const { t } = useTranslation();
  const [walletValue] = useState('THH44sRNX77bG5mTpRdbdG7DMmudFYfct8');

  const handleCopy = () => {
    navigator.clipboard.writeText(walletValue);
  };

  return (
    <div className="helpTheProject">
      <h1 className="helpTheProject__title">{t('helpTheProject-title')}</h1>
      <h2 className="helpTheProjec__text">{t('helpTheProjec-text')}</h2>
      <div className="helpTheProject__wallet">
        <div className="helpTheProject__wallet-container">
          <input
            className="helpTheProject__wallet-input"
            value={walletValue}
            readOnly
          />
          <button
            className="helpTheProject__wallet-button"
            onClick={handleCopy}
          >
            {t('helpTheProject-wallet-button')}
          </button>
        </div>
      </div>
      <p className="helpTheProject__ps">{t('helpTheProject-ps')}</p>
    </div>
  );
}

export default HelpTheProject;
