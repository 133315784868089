import React from 'react';

function TelegramPage() {
  return (
    <div className="telegram-page">
      <h2 className="telegram-page__title">Uuups...</h2>
      <p className="telegram-page__text">It's coming!</p>
    </div>
  );
}

export default TelegramPage;
