import React from 'react';
import { useTranslation } from 'react-i18next';

function Updates() {
  const { t } = useTranslation();

  return (
    <div className="updates">
      <h1 className="updates__title">{t('updates-title')}</h1>
      <p className="updates__text">{t('updates-text-one')}</p>
      <ul className="updates__list">
        <li className="updates__list-item">{t('updates-list-item-one')}</li>
        <li className="updates__list-item">{t('updates-list-item-two')}</li>
        <li className="updates__list-item">{t('updates-list-item-three')}</li>
        <li className="updates__list-item">{t('updates-list-item-four')}</li>
      </ul>
      <p className="updates__text">{t('updates-text-two')}</p>
      <ul className="updates__list">
        <li className="updates__list-item">{t('updates-list-item-five')}</li>
      </ul>
    </div>
  );
}

export default Updates;
