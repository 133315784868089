import AbramsDeadlockChaos from './images/AbramsDeadlockChaos.jpeg';
import SevenDeadlockChaos from './images/SevenDeadlockChaos.jpg';
import BebopDeadlockChaos from './images/BebopDeadlockChaos.jpeg';
import DynamoDeadlockChaos from './images/DynamoDeadlockChaos.jpeg';
import GreyTalonDeadlockChaos from './images/GreyTalonDeadlockChaos.jpeg';
import HazeDeadlockChaos from './images/HazeDeadlockChaos.jpeg';
import InfernusDeadlockChaos from './images/InfernusDeadlockChaos.jpeg';
import IvyDeadlockChaos from './images/IvyDeadlockChaos.jpeg';
import KelvinDeadlockChaos from './images/KelvinDeadlockChaos.jpg';
import LeadyGeistDeadlockChaos from './images/LeadyGeistDeadlockChaos.jpg';
import LashDeadlockChaos from './images/LashDeadlockChaos.jpg';
import McGinnigsDeadlockChaos from './images/McGinnigsDeadlockChaos.jpg';
import MoKrillDeadlockChaos from './images/MoKrillDeadlockChaos.jpg';
import ParadoxDeadlockChaos from './images/ParadoxDeadlockChaos.jpg';
import PocketDeadlockChaos from './images/PocketDeadlockChaos.jpg';
import VindictaDeadlockChaos from './images/VindictaDeadlockChaos.jpg';
import WardenDeadlockChaos from './images/WardenDeadlockChaos.jpg';
import WraithDeadlockChaos from './images/WraithDeadlockChaos.jpg';
import YamatoDeadlockChaos from './images/YamatoDeadlockChaos.jpg';
import ViscouseDeadlockChaos from './images/ViscouseDeadlockChaos.jpg';
import ShivDeadlockChaos from './images/ShivDeadlockChaos.jpg';
import MirageDeadlockChaos from './images/MirageDeadlockChaos.jpg';

const charactersData = [
  { src: AbramsDeadlockChaos, alt: 'Abrams', wr: '51.1' },
  { src: SevenDeadlockChaos, alt: 'Seven', wr: '49.6' },
  { src: BebopDeadlockChaos, alt: 'Bebop', wr: '49.0' },
  { src: DynamoDeadlockChaos, alt: 'Dynamo', wr: '45.9' },
  { src: GreyTalonDeadlockChaos, alt: 'Grey Talon', wr: '47.5' },
  { src: HazeDeadlockChaos, alt: 'Haze', wr: '50.5' },
  { src: InfernusDeadlockChaos, alt: 'Character Name', wr: '50.9' },
  { src: IvyDeadlockChaos, alt: 'Character Name', wr: '50.6' },
  { src: LeadyGeistDeadlockChaos, alt: 'Character Name', wr: '54.7' },
  { src: KelvinDeadlockChaos, alt: 'Character Name', wr: '48.6' },
  { src: LashDeadlockChaos, alt: 'Character Name', wr: '50.0' },
  { src: McGinnigsDeadlockChaos, alt: 'Character Name', wr: '53.3' },
  { src: MoKrillDeadlockChaos, alt: 'Character Name', wr: '53.2' },
  { src: ParadoxDeadlockChaos, alt: 'Character Name', wr: '45.9' },
  { src: PocketDeadlockChaos, alt: 'Character Name', wr: '50.7' },
  { src: VindictaDeadlockChaos, alt: 'Character Name', wr: '46.9' },
  { src: WardenDeadlockChaos, alt: 'Character Name', wr: '51.9'},
  { src: WraithDeadlockChaos, alt: 'Character Name', wr: '48.7' },
  { src: YamatoDeadlockChaos, alt: 'Character Name', wr: '49.0' },
  { src: ViscouseDeadlockChaos, alt: 'Character Name', wr: '48.2' },
  { src: ShivDeadlockChaos, alt: 'Character Name', wr: '48.0' },
  { src: MirageDeadlockChaos, alt: 'Mirage', wr: '53.6' },
];

charactersData.sort((a, b) => parseFloat(b.wr) - parseFloat(a.wr));

export default charactersData;