import React, { useEffect } from 'react';
import logo from '../images/header-logo.png';
import LanguageSwitcher from './LanguageSwitcher';

function Header() {
  useEffect(() => {
    const container = document.querySelector('.header__logo-buttons-container');
    const caption = document.querySelector('.header__logo-caption');

    const startAnimation = () => {
      setTimeout(() => {
        caption.classList.add('visible');
      }, 4000);

      container.innerHTML = '';

      setTimeout(() => {
        caption.classList.remove('visible');
      }, 4500);

      container.innerHTML = '';

      for (let i = 0; i < 9; i++) {
        const button = document.createElement('div');
        button.className = 'header__logo-button';
        button.style.animationDelay = `${i * 0.5}s`;
        container.appendChild(button);
      }
    };

    startAnimation();

    const intervalId = setInterval(startAnimation, 4500);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <header className="header">
      <LanguageSwitcher /> 
      <div className="header__container">
        <div className="header__logo-container">
          <img
            src={logo}
            className="header__logo"
            alt="A beige calculator with buttons burning bright red that calculates the chance of winning and gives the result: You win!"
          />
          <div className="header__logo-caption">You win!</div>
          <div className="header__logo-buttons-container"></div>
        </div>
        <p className="header__logo-title">Deadlock Winrate Calculator</p>
      </div>
    </header>
  );
}

export default Header;
