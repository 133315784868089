import React from 'react';
import { useTranslation } from 'react-i18next';

function HowItWorks() {
  const { t } = useTranslation();

  return (
    <div className="howItWorks">
      <h1 className="howItWorks__title">{t('howItWorks-title')}</h1>
      <p className="howItWorks__text">{t('howItWorks-text-one')}</p>
      <ul className="howItWorks__list">
        <li className="howItWorks__list-item">
          {t('howItWorks-list-item-one')}
        </li>
        <li className="howItWorks__list-item">
          {t('howItWorks-list-item-two')}
        </li>
        <li className="howItWorks__list-item">
          {t('howItWorks-list-item-three')}
        </li>
        <li className="howItWorks__list-item">
          {t('howItWorks-list-item-four')}
        </li>
        <li className="howItWorks__list-item">
          {t('howItWorks-list-item-five')}
        </li>
        <li className="howItWorks__list-item">
          {t('howItWorks-list-item-six')}
        </li>
      </ul>
      <p className="howItWorks__text">{t('howItWorks-text-two')}</p>
      <h2 className="howItWorks__title">{t('howItWorks-title-two')}</h2>
      <ul className="howItWorks__list">
        <li className="howItWorks__list-item">
          {t('howItWorks-list-item-seven')}
        </li>
        <li className="howItWorks__list-item">
          {t('howItWorks-list-item-eight')}
        </li>
        <li className="howItWorks__list-item">
          {t('howItWorks-list-item-nine')}
        </li>
        <li className="howItWorks__list-item">
          {t('howItWorks-list-item-ten')}
        </li>
        <li className="howItWorks__list-item">
          {t('howItWorks-list-item-eleven')}
        </li>
        <li className="howItWorks__list-item">
          {t('howItWorks-list-item-twelve')}
        </li>
      </ul>
    </div>
  );
}

export default HowItWorks;
