import React from 'react';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './Header.js';
import NavBar from './NavBar.js';
import Main from './Main.js';
import Calculator from './Calculator.js';
import HowItWorks from './HowItWorks.js';
import TelegramPage from './TelegramPage.js';
import HelpTheProject from './HelpTheProject.js';
import Updates from './Updates.js';
import Footer from './Footer.js';
import '../i18n.js';

function App() {
  return (
    <Router>
      <Header />
      <NavBar />
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/how-it-works" element={<HowItWorks />} />
        <Route path="/telegram-page" element={<TelegramPage />} />
        <Route path="/help-the-project" element={<HelpTheProject />} />
        <Route path="/calculator" element={<Calculator />} />
        <Route path="/updates" element={<Updates />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
