/* global ym */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import charactersData from '../deadlockTrackerParser.js';
import { useTranslation } from 'react-i18next';

function Calculator() {
  const { t } = useTranslation();
  const [teamOne, setTeamOne] = useState(Array(6).fill(null));
  const [teamTwo, setTeamTwo] = useState(Array(6).fill(null));
  const [result, setResult] = useState('');
  const [calculated, setCalculated] = useState(false);
  const [selectedCharacter, setSelectedCharacter] = useState(null);

  const handleCharacterClick = (character) => {
    setSelectedCharacter(character);
  };

  const handleCellClick = (team, index) => {
    if (selectedCharacter) {
      if (team === 'teamOne') {
        const updatedTeamOne = [...teamOne];
        updatedTeamOne[index] = selectedCharacter;
        setTeamOne(updatedTeamOne);
      } else if (team === 'teamTwo') {
        const updatedTeamTwo = [...teamTwo];
        updatedTeamTwo[index] = selectedCharacter;
        setTeamTwo(updatedTeamTwo);
      }
      setSelectedCharacter(null);
    }
  };

  const calculateWinRate = () => {
    if (teamOne.includes(null) || teamTwo.includes(null)) {
      setResult(t('errorMessage'));
      return;
    }

    const getTotalWinRate = (team) => {
      return team.reduce(
        (total, character) =>
          total + (character ? parseFloat(character.wr) : 0),
        0
      );
    };

    const teamOneWinRate = getTotalWinRate(teamOne);
    const teamTwoWinRate = getTotalWinRate(teamTwo);

    const winChance = (
      (teamOneWinRate / (teamOneWinRate + teamTwoWinRate)) *
      100
    ).toFixed(2);
    setResult(t('winChanceMessage', { chance: winChance }));
    setCalculated(true);
  };

  const resetTeams = () => {
    setTeamOne(Array(6).fill(null));
    setTeamTwo(Array(6).fill(null));
    setResult('');
    setCalculated(false);
    setSelectedCharacter(null);
  };

  const renderTeamCharacters = (team, teamName) =>
    team.map((character, index) => (
      <div
        key={index}
        className={`calculator__${teamName}-character`}
        onClick={() => handleCellClick(teamName, index)}
        style={{
          outline: selectedCharacter ? '2px dashed #0088cc' : 'none',
        }}
      >
        {character && <img src={character.src} alt={character.alt} />}
      </div>
    ));

  return (
    <div className="calculator-all-container">
      {!calculated ? (
        <div className="calculator">
          <h1 className="calculator__characters">
            {t('calculator-characters')}
          </h1>
          <div className="calculator__characters-container">
            {charactersData.map((character, index) => (
              <div
                key={index}
                className={`calculator__characters-container-item ${
                  selectedCharacter === character ? 'selected' : ''
                }`}
                onClick={() => handleCharacterClick(character)}
              >
                <img
                  src={character.src}
                  alt={character.alt}
                  className="calculator__characters-container-img"
                />
              </div>
            ))}
          </div>
          <div className="calculator__teams-container">
            <div className="calculator__team-one">
              <h2 className="calculator__team-one-title">
                {t('calculator-team-one-title')}
              </h2>
              <div className="calculator__team-area">
                {renderTeamCharacters(teamOne, 'teamOne')}
              </div>
            </div>
            <div className="calculator__teams-vs">VS</div>
            <div className="calculator__team-two">
              <h2 className="calculator__team-two-title">
                {t('calculator-team-two-title')}
              </h2>
              <div className="calculator__team-area">
                {renderTeamCharacters(teamTwo, 'teamTwo')}
              </div>
            </div>
          </div>
          <button
            className="calculator__result-button"
            onClick={calculateWinRate}
          >
            {t('calculator-result-button')}
          </button>
          <button className="calculator__reset-button" onClick={resetTeams}>
            {t('calculator-reset-button')}
          </button>
          {result && <div className="calculator__result">{result}</div>}
        </div>
      ) : (
        <div className="result-container">
          <svg
            width="100"
            height="100"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 16.172L4.828 12 3.414 13.414l5.656 5.656L21.586 7.414 20.172 6l-11.172 11.172z"
              fill="#00ff7f"
            />
          </svg>
          <h1 className="result-container__result">{result}</h1>
          <Link
            to="/"
            className="calculate-again-button"
            onClick={() => ym(98258836, 'hit', {})}
          >
            {t('calculate-again-button')}
          </Link>
        </div>
      )}
    </div>
  );
}

export default Calculator;
