import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

function LanguageSwitcher() {
  const { i18n } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);

  useEffect(() => {
    setSelectedLanguage(i18n.language);
  }, [i18n.language]);

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    setSelectedLanguage(lang);
  };

  return (
    <div className="languageSwitcher">
      <button
        className={`languageSwitcher__button ${
          selectedLanguage === 'en' ? 'active' : ''
        }`}
        onClick={() => changeLanguage('en')}
      >
        EN
      </button>
      <button
        className={`languageSwitcher__button ${
          selectedLanguage === 'ru' ? 'active' : ''
        }`}
        onClick={() => changeLanguage('ru')}
      >
        RU
      </button>
    </div>
  );
}

export default LanguageSwitcher;
