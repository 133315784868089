import React from 'react';
import CalculatorButtonStart from './CalculatorButtonStart.js';

function Main() {
  return (
    <main className="main">
      <CalculatorButtonStart />
    </main>
  );
}

export default Main;
