/* global ym */
import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function CalculatorButtonStart() {
  const { t } = useTranslation();
  const handleButtonClick = () => {
    ym(98258836, 'hit', {});
  };

  return (
    <>
      <h1 className="calculator-button-start-heading">
        {t('calculator-button-start-heading-three')}
      </h1>
      <h2 className="calculator-button-start-heading">
        {t('calculator-button-start-heading-one')}
      </h2>
      <Link
        to="/calculator"
        className="calculator-button-main-container"
        onClick={handleButtonClick}
      >
        <button className="calculator-button-start">
          <div className="matrix-odd">
            D3KJ9ZX7F0LB8QR1CVMW4SP2YNAH6GTEO5UIXL7VY03RN8U2TPF9MCIKX6OQJGD5B4Z1EWHSA0R2GLXWV0O1DKUE6CZ8F4NJPTQ5MB73HYA9SIJXK8BVMW2A0Q74U9YRPGS1FLZJTO6HD3NEC5I7YXUR2M4VQ1LZK8O5JNFC6WB9HTI03DSGPA7E
          </div>
          <div className="matrix-honest">
            3G8DJXZ5K2N4Q9Y1TR0UAVM7WPHBOISF6CEXLQ9ROJ8ULW3PDZTNVX21KSACM50IGBFY7H4ME6V2X4WMLZB7GDS1JONHPY85KQF6CR3T0I9EUAVF1OC7PUJ5K3L9QDZNTXR2SYV0WGMBI48EH6AO7A8BPO5VR2DS3ULKXJ16M4NZT9QHWYCG0EIFV
          </div>
          <div className="matrix-odd">
            F3RW9ZMQB5DKG2X4L7JSP1YUOTCN6H0V8AERIX2NQ3YTJ1MW7L9VRDZF64OKG8P0US5BCAIHXQ0B5RG7WLFPYX19KSZ2N48TDJMCAI3UV60OHXX7NR5VWLDQF13SPZ2MY9K4U6GTJO0HBC8EAXMV19RYZGQX6N3L7SPBT4DWFK20OJ8CMA5UIHVE
          </div>
          <div className="matrix-honest">
            K7B0Q5PXJ8V2NZ4F1LUWH9TMC6OD3AGYIRSXE4M0W7CXLZVJ8G9YKNQ3B2TPSRF15DO6U1HVEAXJ3PB9FW4K2NGZ8T1LY7SV0RQ6D5OUCAHIMXE4OJZQ7K5WLMV8BY3XT1N0CSFR62D9PGHUEAIXK4B9V1G5L8YQZX2MW0TRP3NS7JFDU6OHAICEV
          </div>
          <div className="matrix-odd">
            9QFB5R3XP7YGL1WNKD6V2S4MT0CUZJ8OHAXIE0L6GV4RPX5WY1QZ8MB93D2TFJKC7NSOUAIHEOT2G4QX7B5MWY1P9KD0NS3VJFL8ZRC6UHOIAE1V7P5MKXL8YZG0DQ4WRN3T9SF2B6COUJAIHEXQY1V6GR5ZKXP3BW4DL8T0M9JF72CNSUOAIHEX
          </div>
          <div className="matrix-honest">
            F9W4KZR7Y1L0DXV8QP5JM3NSG2BT6OCUHIAE2W0T7DXP9K5Y4R8B1LQ3GZJVS6MCNUFHOIAEH7L8Z5YDR4P1QW3X9JKM2V0SNT6CGUFBAOIEHXM7J5ZR3P9W4KYV8Q1LX0G2DT6NFUBOIAHECXSB9W3Z5P7K1YX4TQ8RL0JMGV6DN2CSOFUHIAEX
          </div>
          <div className="matrix-odd">
            7PG2X5YW4R1VLZ9KQ8JD3NST0MCFUBOIE6AHE5KQ3P7YZ9R2X1D4WLV8JNTMG0CFBUOSIA6EHXT9Z5X3P7V1W4RLQ2JK8YDNG0MSFCBUOAIE6HXL5YZ7P4X3W9Q1R2VJ8KTND0MGCFBUOIAHE6XS5ZK4P7Y1X9T3QR2W8JL0VDNGCMFSBUIAOHE6X
          </div>
          <div className="matrix-honest">
            9Q5K7X4P1Y2ZR8D3WLVJ0NGTMSCFUBOAIE6HX5KQ4P7YZ9R3X1D2WLV8JNTMG0CFBUOIAHE6XS8L4Z5Y1P7Q3XR9W2KJDVT0NGMCSBUOIAHE6FXP5YZ7Q4X3W9L1R2VJ8KTND0MGCFBUOIAHE6XSX5ZK4P1Y7Q3TR9W8LJ2V0NDGMCSBUIAOHE6XF
          </div>
          <div className="calculator-button-text-container">
            <p className="calculator-button-text">
              {t('calculator-button-text')}
            </p>
          </div>
        </button>
      </Link>
      <p className="calculator-button-link">
        {t('calculator-button-link-part-one')}{' '}
        <Link
          to="/how-it-works"
          className="calculator-button-link-span"
          onClick={() => ym(98258836, 'hit', {})}
        >
          {t('calculator-button-link-part-two')}
        </Link>
        {t('calculator-button-link-part-three')}
      </p>
      <div className="calculator-button-promo-text-container">
        <p className="calculator-button-start-text">
          {t('calculator-button-start-text-one')}
        </p>
        <p className="calculator-button-start-text">
          {t('calculator-button-start-text-two')}
        </p>
        <ul className="calculator-button-start-list">
          <li className="calculator-button-start-list-item">
            {t('calculator-button-start-list-item-one')}
          </li>
          <li className="calculator-button-start-list-item">
            {t('calculator-button-start-list-item-two')}
          </li>
          <li className="calculator-button-start-list-item">
            {t('calculator-button-start-list-item-three')}
          </li>
        </ul>
        <p className="calculator-button-start-text">
          {t('calculator-button-start-text-three')}
        </p>
        <p className="calculator-button-start-text">
          {t('calculator-button-start-text-four')}
        </p>
      </div>
    </>
  );
}

export default CalculatorButtonStart;
